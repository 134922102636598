.container {
  @apply flex items-center;
}

.assetLogo svg {
  @apply mr-2 w-5 h-5 rounded-full;
}

.asset {
  @apply uppercase font-semibold text-xs;
}
