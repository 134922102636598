html {
  --color-primary: #fec004;
  --color-primary-content: #ffffff;
  --color-secondary: #2a8fcc;
  --color-secondary-content: #ffffff;
  --color-content-main: #0D0D0F;
}

html[data-theme='light'] {
  --color-primary: #570df8;
  --color-primary-content: #0D0D0F;
  --color-secondary: #f000b8;
  --color-secondary-content: #ffffff;
  --color-content-main: #ffffff;
}

html[data-theme='dark'] {
  --color-primary: #661ae6;
  --color-primary-content: #ffffff;
  --color-secondary: #d926aa;
  --color-secondary-content: #ffffff;
  --color-content-main: #0D0D0F;
}

html[data-theme='coffee'] {
  --color-primary: #db924b;
  --color-primary-content: #263e3f;
  --color-secondary: #263e3f;
  --color-content-main: #756e63;
}
