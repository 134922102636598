.wrapper {
  @apply w-full lg:min-h-24 lg:py-4 lg:mt-9 mt-5 lg:px-12 p-4 gap-12 lg:flex justify-end items-center bg-gray-70 rounded bg-no-repeat;
  background-image: url('../../../assets/images/banner_bg.png');
  background-position-x: 1rem;
  background-position-y: 1rem;
  background-size: 9rem;
}

.depositsOver {
  @apply flex items-center mt-14 sm:mt-0 flex-col lg:flex-row;
}

.depositsOpen {
  @apply flex flex-col justify-end lg:max-w-full max-w-44 lg:ml-0 ml-auto;
}

@screen sm {
  .wrapper {
    background-size: 10rem;
  }
}

@screen xl {
  .wrapper {
    background-position-x: 2rem;
    background-position-y: center;
    background-size: auto;
  }
}
