
.countdown {
  @apply flex justify-center items-center gap-8 font-medium;
  div {
    @apply text-center;
    span {
      @apply lg:text-white text-gray-30 text-xs;
    }
    div {
      @apply text-base lg:bg-gray-20 bg-gray-80 mt-1 rounded lg:w-20 w-11 h-11 flex items-center justify-center lg:text-sovryn-black;
    }
  };
}

.minimal {
  @apply flex justify-center items-center gap-8 font-medium;
  div {
    @apply text-center;
    span {
      @apply text-gray-30 text-xs;
    }
    div {
      @apply text-base bg-gray-80 mt-1 rounded w-11 h-11 flex items-center justify-center;
    }
  };
}
