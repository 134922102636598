.button[type='button'] {
  @apply flex items-center bg-gray-60 border-gray-60 justify-between relative h-full min-w-20 whitespace-nowrap font-body text-gray-10 text-xs font-semibold leading-none cursor-pointer select-none transition-colors border rounded;

  &.isOpen, &.isActive {
    @apply bg-gray-80 border-gray-80;
  }

  &:hover {
    @apply border-gray-50;
  }

  &.small {
    @apply h-8 px-2;
  }

  &.large {
    @apply h-10 px-2.5;
  }
}

.dropdown {
  @apply static overflow-x-auto transition-colors z-[9999] opacity-0 w-full lg:fixed lg:mt-1 h-0;

  &.isVisible {
    @apply opacity-100 h-auto;
  }
}

.icon {
  @apply transition-transform ml-2;
  &.isOpen {
    @apply transform rotate-180;
  }
}
